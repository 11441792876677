:root {
  --color-dark-blue-1: #012d6c;
  --color-dark-blue-2: #335789;
  --color-dark-blue-3: #6681a6;
  --color-dark-blue-4: #99abc4;
  --color-dark-blue-5: #ccd5e1;
  --color-light-blue-1: #0071bd;
  --color-light-blue-2: #338dca;
  --color-light-blue-3: #66a9d7;
  --color-light-blue-4: #99c6e4;
  --color-light-blue-5: #cce2f1;
  --color-yellow-1: #f9ae3b;
  --color-yellow-2: #fabe62;
  --color-yellow-3: #fbce89;
  --color-yellow-4: #fcdeb0;
  --color-yellow-5: #fdeed7;
  --color-orange-1: #ff6a40;
  --color-orange-2: #ff8766;
  --color-orange-3: #ffa58c;
  --color-orange-4: #ffc3b2;
  --color-orange-5: #ffe1d8;
  --color-grey-1: #3f3f3f;
  --color-grey-2: #9b9b9b;
  --color-grey-3: #ececec;
  --color-grey-4: #f8f8f8;
  --color-white: #ffffff;
}

html {
  font-size: 16px;
  font-family: 'Montserrat', 'Arial', sans-serif;
  letter-spacing: 0;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  overflow-x: hidden;
}

@media (max-width: 1439px) {
  html {
    font-size: 12.8px;
  }
}

.elementor-screen-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* header */

.main-header {
  position: absolute;
  margin: 0;
  width: 100%;
  z-index: 30;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 30px 90px;
  box-sizing: border-box;
  font-weight: 600;
}

@media (max-width: 1439px) {
  .main-header {
    padding: 30px 6.25vw;
  }
}

@media (max-width: 959px) {
  .main-header {
    padding: 33px 24px;
  }
}

.main-header__dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.main-header__dropdown-container--opened {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 48px);
  max-width: 320px;
  flex-direction: column;
  padding: 35px;
  /* justify-content: center; */
  background-color: #0071bd;
  z-index: 50;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.main-header--opened::before {
  content: '';
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .main-header__dropdown-container--opened {
    padding: 33px 24px;
  }
}

.main-header.blog-main-header .nav-buttons .register-button {
  padding: 10px 30px;
  font-size: 15px;
  line-height: 18px;
  background: #f9ae3b;
  border-radius: 8px;
}

.main-header.blog-main-header .nav-buttons .register-button:hover {
  background: linear-gradient(180deg,
      rgba(249, 174, 59, 0.7) 0%,
      rgba(249, 174, 59, 0.7) 100%);
}

.header-nav.active {
  display: block;
  color: #ffffff;
  text-align: center;
  width: 50%;
}

.header-logo img {
  display: block;
  width: 80px;
  height: 24px;
}

.main-header__dropdown-container--opened .header-logo img {
  width: 80px;
  height: 24px;
}

.nav-links a,
.nav-buttons .login-button,
.nav-buttons .register-button {
  font-size: 15px;
  line-height: 18px;
}

@media (max-width: 767px) {
  .header-logo img {
    width: 58px;
    height: 17px;
  }

  .main-header__dropdown-container--opened .header-logo img {
    width: 58px;
    height: 17px;
  }
}

@media (max-width: 1439px) {
  .header-nav .nav-links li:hover {
    background: none;
  }

  .header-nav.active .nav-links li:hover {
    background: none;
    cursor: pointer;
  }
}

@media (min-width: 1200px) {
  .header-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .nav-links li {
    color: var(--color-white);
    padding: 13px 18px;
    border-radius: 8px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
  }

  .nav-links li:nth-last-child(1) {
    margin-right: 0;
  }

  .nav-links li.sub-menu-parent {
    position: relative;
  }

  .header-nav .nav-links>li>a {
    position: relative;
  }

  .header-nav .nav-links>li>a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #f9ae3b;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  .header-nav .nav-links>li:hover>a:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media (max-width: 1199px) {
    .sub-menu-parent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .nav-links .sub-menu {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: -1;
    /* transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; */
  }

  .nav-links .sub-menu li a {
    transition: transform 0.3s linear;
  }

  .nav-links .sub-menu li a:hover {
    color: #f9ae3b;
    transform: translateX(10px);
  }

  .nav-links .sub-menu-parent .caret-image {
    position: relative;
    bottom: 0px;
    margin-left: 0.9rem;
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
  }

  .nav-links li.sub-menu-parent:hover .caret-image {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg) !important;
  }

  .nav-links .sub-menu li {
    font-weight: 400;
    color: #013d7f;
    /* text-shadow: #012D6Cb3 2px 0 10px; */
  }

  .nav-links .sub-menu-parent:focus .sub-menu,
  .nav-links .sub-menu-parent:focus-within .sub-menu,
  .nav-links .sub-menu-parent:hover .sub-menu {
    position: absolute;
    visibility: visible;
    display: block;
    opacity: 1;
    z-index: 1;
    /* transition-delay: 0s, 0s, 0.3s;  */
  }

  /* .nav-links .sub-menu-parent:hover .sub-menu {
    background: linear-gradient(
      93.29deg,
      rgba(255, 255, 255, 0.1) 1.54%,
      rgba(255, 255, 255, 0) 127.77%
    );
    background: linear-gradient(265.74deg, #1179BF66 -10%, #012D6C 50%);
  } */

  /* .header-nav .nav-links > li.sub-menu-parent:hover {
    background: linear-gradient(
      93.29deg,
      rgba(255, 255, 255, 0.1) 1.54%,
      rgba(255, 255, 255, 0) 127.77%
    );
    background: linear-gradient(265.74deg, #1179BF66 -10%, #012D6C 50%);
  } */

  .nav-links .sub-menu-parent:hover .sub-menu {
    background: #fff;
    box-shadow: 0 4px 60px rgb(0 0 0 / 20%);
    border-radius: 10px;
    padding: 7px 0;
  }

  .nav-buttons {
    display: flex;
    margin-left: 24px;
  }

  .nav-buttons a {
    display: block;
    padding: 10px 30px;
    min-width: 130px;
    box-sizing: border-box;
    text-align: center;
  }

  .nav-buttons .login-button {
    background: linear-gradient(93.29deg,
        rgba(255, 255, 255, 0.1) 1.54%,
        rgba(255, 255, 255, 0) 127.77%);
    color: var(--color-white);
    border-radius: 8px 0 0 8px;
  }

  .nav-buttons .register-button {
    background-color: var(--color-yellow-1);
    color: white;
    border-radius: 0 8px 8px 0;
  }

  .nav-buttons .login-button:hover {
    background: linear-gradient(93.29deg,
        rgba(255, 255, 255, 0.35) 1.54%,
        rgba(255, 255, 255, 0) 127.77%);
  }

  .nav-buttons .register-button:hover {
    background: linear-gradient(180deg,
        rgba(249, 174, 59, 0.7) 0%,
        rgba(249, 174, 59, 0.7) 100%);
  }
}

@media (max-width: 1199px) {
  .header-nav {
    display: none;
  }

  .header-nav.active {
    color: #ffffff;
    display: block;
    text-align: center;
    width: 100%;
  }

  .nav-links li.sub-menu-parent {
    border-radius: 8px 8px 0 0;
    position: relative;
  }

  .nav-links .sub-menu-parent .caret-image {
    position: absolute;
    right: 2rem;
  }

  .rotate {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg) !important;
  }

  .nav-links .sub-menu {
    border-radius: 0 0 8px 8px;
    display: none;
    width: 100%;
    padding-top: 35px;
  }

  .nav-links .sub-menu li:not(:last-child) {
    padding-bottom: 35px;
  }

  .nav-links .sub-menu li a {
    font-weight: 300;
  }

  .nav-links .sub-menu-parent:hover .sub-menu {
    background: transparent;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .main-header__dropdown-container--opened {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .header-nav.active {
    display: flex;
    flex-direction: column;
  }

  .header-nav.active .nav-buttons {
    display: flex;
    flex-direction: row;
    padding: 0px !important;
  }

  .header-nav.active .nav-buttons .login-button {
    border-radius: 15px 0 0 15px !important;
    margin-bottom: 0;
    height: 100%;
    vertical-align: middle;
    align-self: stretch;
  }

  .header-nav.active .nav-buttons .register-button {
    border-radius: 0 15px 15px 0 !important;
    height: 100%;
    vertical-align: middle;
    align-self: stretch;
  }

  .main-header__dropdown-container--opened {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .blog-main-header .header-logo img {
    width: 149px;
    height: 32px;
  }
}

.header-nav.active .nav-links {
  margin: 0;
  width: 100%;
  padding: 56px 0 50px;
  box-sizing: border-box;
}

/* .header-nav .nav-links > li:hover {
  background: linear-gradient(265.74deg, #1179BF66 -30%, #012D6Cb3 90%);
} */

.header-nav.active .nav-links>li {
  padding: 24px 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

.header-nav.active .nav-buttons {
  margin: 0;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.header-nav.active .nav-buttons .login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 30px;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(93.29deg,
      rgba(255, 255, 255, 0.1) 1.54%,
      rgba(255, 255, 255, 0) 127.77%);
}

.header-nav.active .nav-buttons .login-button:hover {
  background: linear-gradient(93.29deg,
      rgba(255, 255, 255, 0.35) 1.54%,
      rgba(255, 255, 255, 0) 127.77%);
}

@media (max-width: 767px) {
  .header-nav.active .nav-buttons .login-button {
    padding: 15px;
  }
}

.header-nav.active .nav-buttons a {
  margin-bottom: 32px;
}

.header-nav.active .nav-buttons a:nth-last-child(1) {
  margin-bottom: 0;
  white-space: nowrap;
}

.header-nav.active .nav-buttons .register-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 30px;
  text-align: center;
  border-radius: 8px;
  background: #f9ae3b;
}

@media (max-width: 767px) {
  .header-nav.active .nav-buttons .register-button {
    padding: 15px;
  }
}

.header-nav.active a {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.header-logo {
  display: block;
  text-align: center;
}

.header-burger {
  position: absolute;
  z-index: 50;
  top: 18px;
  right: 20px;
  display: none;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

@media (max-width: 959px) {
  .header-burger {
    top: 20px;
  }
}

@media (max-width: 767px) {
  .header-burger {
    right: 11px;
    top: 18px;
  }
}

.header-burger--opened {
  position: fixed;
}

@media (max-width: 1199px) {
  .header-burger {
    display: block;
  }
}

.burger-button {
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  background-color: transparent;
  cursor: pointer;
  background-image: url(../images/burger.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 22px 18px;
  border-radius: 4px;
  box-sizing: border-box;
  transition: background-color 300ms ease-in-out;
}

.burger-button--opened {
  background-size: 18px;
  background-image: url(../images/cross.svg);
}

.burger-button:focus,
.burger-button.focus {
  border-color: transparent;
  outline: none;
}

.burger-button:focus:not(:focus-visible) {
  border-color: transparent;
  outline: none;
}

.burger-button:focus-visible,
.burger-button.focus-visible {
  border-color: transparent;
  outline: none;
}

.burger-button:disabled,
.burger-button.disabled {
  opacity: 0.3;
  cursor: default;
}

.burger-button:active,
.burger-button.active {
  background-color: #66a9d7;
  outline: none;
  border-color: transparent;
}

/* footer */

.footer {
  color: var(--color-dark-blue-1);
  background-color: var(--color-white);
}

.footer .container {
  max-width: 1440px;
  padding: 0 64px 40px;
  margin: 0 auto;
}

.footer__inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.footer__block {
  padding: 36px 24px;
  width: 23%;
  box-sizing: border-box;
}

.footer__block--big {
  width: 31%;
}

@media (max-width: 959px) {

  .footer__block,
  .footer__block--big {
    width: 50%;
  }
}

@media (max-width: 599px) {

  .footer__block,
  .footer__block--big {
    width: 100%;
  }
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
}

.footer__border {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box;
}

.footer__border--top {
  padding-bottom: 100px;
}

.footer__border--bottom {
  padding-bottom: 40px;
}

.footer__border--top .footer__border-inner {
  height: 1px;
  background-color: #ccd5e1;
}

.footer__border--bottom .footer__border-inner {
  height: 1px;
  background-color: #ececec;
}

@media (max-width: 1439px) {
  .footer .container {
    padding-left: 4.44vw;
    padding-right: 4.44vw;
  }
}

@media (max-width: 767px) {
  .footer__border {
    padding-right: 24px;
    padding-left: 24px;
  }

  .footer__border--top {
    padding-bottom: 28px;
  }

  .footer__border--bottom {
    padding-bottom: 40px;
  }
}

.footer__logo {
  display: block;
  margin-bottom: 30px;
}

.footer__copyright {
  text-align: center;
  font-size: 14px;
}

@media (max-width: 599px) {
  .footer__copyright {
    padding: 0 36px;
    margin: 0 auto 0 0;
  }
}

.footer__lead {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark-blue-1);
}

.footer__company-list {
  margin: 0 0 27px 0;
  padding: 0;
  list-style: none;
}

.footer__company-list li {
  margin-bottom: 16px;
}

.footer__company-list li:nth-last-child(1) {
  margin-bottom: 0;
}

.footer__company-list span {
  display: block;
  padding-left: 42px;
  line-height: 34px;
  font-size: 14px;
  color: var(--color-dark-blue-1);
  background-size: 34px auto;
  background-position: top 0 left 0;
  background-repeat: no-repeat;
}

.footer__company-list li:nth-child(1) span {
  background-image: url(../images/footer/locations/1.svg);
}

.footer__company-list li:nth-child(2) span {
  background-image: url(../images/footer/locations/2.svg);
}

.footer__company-list li:nth-child(3) span {
  background-image: url(../images/footer/locations/3.svg);
}

.footer__subscribe {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  width: 70%;
  color: var(--color-dark-blue-1);
}

.footer__socials {
  display: flex;
}

.footer__socials li {
  margin-right: 18px;
}

.footer__socials li:nth-last-child(1) {
  margin-right: 0;
}

.footer__socials a {
  display: block;
  width: 28px;
  height: 28px;
  background-position: center;
  background-size: 28px auto;
  background-repeat: no-repeat;
}

.footer__socials li:nth-child(1) a {
  background-image: url(../images/footer/socials/linkedin.svg);
}

.footer__socials li:nth-child(2) a {
  background-image: url(../images/footer/socials/facebook.svg);
}

.footer__socials li:nth-child(3) a {
  background-image: url(../images/footer/socials/instagram.svg);
}

.footer__socials li:nth-child(4) a {
  background-image: url(../images/footer/socials/tiktok.svg);
}

.footer h3 {
  margin: 0 0 24px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-dark-blue-1);
}

.footer__pages-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__pages-list li {
  margin-bottom: 24px;
}

.footer__pages-list li:nth-last-child(1) {
  margin-bottom: 0;
}

.footer__pages-list li a {
  font-size: 16px;
  line-height: 20px;
  color: var(--color-dark-blue-1);
}

.footer__partners {
  display: flex;
}

.footer__partners li {
  margin-right: 32px;
}

.footer__partners li img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.footer__partners li:nth-last-child(1) {
  margin-right: 0;
}

.footer__partners li:nth-child(3) img {
  max-height: 35px;
}

.footer__pay {
  display: flex;
}

.footer__pay li {
  margin-right: 22px;
  display: flex;
  align-items: center;
}

.footer__pay li:nth-last-child(1) {
  margin-right: 0;
}

@media (max-width: 959px) {
  .footer__bottom {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer__partners {
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }

  .footer__partners li:nth-child(1) {
    order: 1;
  }

  .footer__partners li:nth-child(2) {
    order: 3;
  }

  .footer__partners li:nth-child(3) {
    order: 2;
  }

  .footer__partners li:nth-child(4) {
    order: 4;
  }

  .footer__partners li:nth-child(5) {
    order: 5;
  }

  .footer__partners li {
    margin: 0 0 32px 0;
  }
}

@media (max-width: 1439px) {
  .footer .container {
    padding: 0 3.75vw 40px;
  }
}

@media (max-width: 767px) {
  .footer__subscribe {
    width: 100%;
  }

  .footer .container {
    padding: 60px 24px 40px;
  }
}

/* call-action */

.call-action {
  display: inline-block;
  padding: 19px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-yellow-1);
  border-radius: 8px;
  cursor: pointer;
  border: 0;
}

@media (max-width: 767px) {
  .call-action {
    padding: 14px 18px;
    font-size: 16px;
    line-height: 20px;
  }
}

.squares-bg {
  position: absolute;
}

.squares-bg img {
  display: block;
  width: 100%;
  height: auto;
}

.squares-bg--left {
  left: 0;
}

.squares-bg--right {
  right: 0;
}

.squares-bg--120 {
  width: 120px;
}

@media (max-width: 1439px) {
  .squares-bg--120 {
    width: 8.33vw;
  }
}

.squares-bg--80 {
  width: 80px;
}

@media (max-width: 1439px) {
  .squares-bg--80 {
    width: 5.5vw;
  }
}

@media (max-width: 959px) {
  .squares-bg {
    display: none;
  }
}

.container {
  max-width: 1440px;
  padding: 0 11.11vw;
}

@media (min-width: 1440px) {
  .container {
    padding: 0 160px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 24px;
  }
}